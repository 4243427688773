// extracted by mini-css-extract-plugin
export var buttons = "styles-module--buttons--c2c0a";
export var buttonsVisible = "styles-module--buttonsVisible--e96ed";
export var card = "styles-module--card--68518";
export var cardContent = "styles-module--cardContent--be8d9";
export var cardNumber = "styles-module--cardNumber--67555";
export var cardTitle = "styles-module--cardTitle--f8aeb";
export var carouselButton = "styles-module--carouselButton--6f3c7";
export var carouselContainer = "styles-module--carouselContainer--618ef";
export var container = "styles-module--container--926a0";
export var content = "styles-module--content--3b582";
export var contentVisible = "styles-module--contentVisible--eecd3";
export var disabledNext = "styles-module--disabledNext--9ce6a";
export var mobileButtons = "styles-module--mobileButtons--05b64";
export var section = "styles-module--section--cc3fb";
export var tagline = "styles-module--tagline--1af35";
export var taglineVisible = "styles-module--taglineVisible--4e893";
export var textContainer = "styles-module--textContainer--9782e";
export var textSection = "styles-module--textSection--86340";
export var title = "styles-module--title--9b96d";
export var titleVisible = "styles-module--titleVisible--022ac";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--53a53";
import React, { useState, Fragment } from 'react';
import { Section, Text, Carousel, Link, Image, OnVisible } from 'components';
import LeftArrow from 'images/icons/white-arrow-left.inline.svg';
import RightArrow from 'images/icons/white-arrow-right.inline.svg';
import * as styles from './styles.module.scss';

const CardCarousel = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { tagline, title } = primary;

  const titleHtml = title?.html;
  const taglineHtml = tagline?.html;

  const [slick, setSlick] = useState(null);
  const [disabled, setDisabled] = useState(null);
  const slickObj = slick?.current;

  const settings = {
    dots: false,
    infinite: false,
    cssEase: 'ease-out',
    arrows: false,
    speed: 300,
    slidesToScroll: 1,
    slidesToShow: 1,
    draggable: !disabled,
    centerMode: false,
    variableWidth: true,
    onEdge: 0,
    edgeFriction: 1,
  };

  // custom button handlers
  const handleLeftClick = () => {
    slickObj.slickPrev();
  };

  const handleRightClick = () => {
    if (!disabled) {
      slickObj.slickNext();
    }
  };

  const visHandler = (isVisible, index) => {
    if (index === items.length - 1 && isVisible) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  return (
    <>
      <Section className={styles.textSection} containerClassName={styles.textContainer} sliceName="CardCarousel">
        <div>
          {taglineHtml && (
            <Text html={taglineHtml} className={styles.tagline} visibleClassName={styles.taglineVisible} />
          )}
          {titleHtml && <Text html={titleHtml} className={styles.title} visibleClassName={styles.titleVisible} h2 />}
        </div>
        <OnVisible className={styles.buttons} visibleClassName={styles.buttonsVisible}>
          <LeftArrow onClick={handleLeftClick} className={styles.carouselButton} />
          <RightArrow
            onClick={handleRightClick}
            className={`${styles.carouselButton} ${disabled ? styles.disabledNext : ''}`}
          />
        </OnVisible>
      </Section>
      <Section className={styles.section} containerClassName={styles.container} sliceName="CardCarousel" noContainer>
        <div className={styles.container}>
          <div className={styles.carouselContainer}>
            <Carousel settings={settings} sliderRef={setSlick}>
              {items.map((item, index) => {
                const { content: cardContent, title: cardTitle } = item;
                return (
                  <Fragment key={cardTitle.text}>
                    <OnVisible
                      className={styles.card}
                      onChange={(event) => visHandler(event, index)}
                      threshold={1}
                      triggerOnce={false}
                    >
                      <span className={styles.cardNumber}>{`${index <= 9 ? 0 : null}${index + 1}`}</span>
                      {cardTitle && <Text html={cardTitle.html} h3 className={styles.cardTitle} />}
                      {cardContent && <Text html={cardContent.html} p1 className={styles.cardContent} />}
                    </OnVisible>
                  </Fragment>
                );
              })}
            </Carousel>
          </div>
          <div className={styles.mobileButtons}>
            <LeftArrow onClick={handleLeftClick} className={styles.carouselButton} />
            <RightArrow
              onClick={handleRightClick}
              className={`${styles.carouselButton} ${disabled ? styles.disabledNext : ''}`}
            />
          </div>
        </div>
      </Section>
    </>
  );
};
export default CardCarousel;

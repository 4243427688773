// extracted by mini-css-extract-plugin
export var authorContainer = "styles-module--authorContainer--e455e";
export var bottomSection = "styles-module--bottomSection--ab57f";
export var buttons = "styles-module--buttons--e0fad";
export var card = "styles-module--card--9cfc1";
export var cardBody = "styles-module--cardBody--7e5b4";
export var carouselButton = "styles-module--carouselButton--faec1";
export var carouselContainer = "styles-module--carouselContainer--b94b3";
export var content = "styles-module--content--126f2";
export var image = "styles-module--image--471e1";
export var name = "styles-module--name--9dd7d";
export var reviewTitle = "styles-module--reviewTitle--6c983";
export var stars = "styles-module--stars--9a5f9";
export var tagline = "styles-module--tagline--d75a1";
export var testimonials = "styles-module--testimonials--b2f57";
export var textContainer = "styles-module--textContainer--18f99";
export var title = "styles-module--title--65433";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--f8208";
// extracted by mini-css-extract-plugin
export var h1 = "styles-module--h1--d0b0a";
export var h2 = "styles-module--h2--59fef";
export var h3 = "styles-module--h3--a5030";
export var h4 = "styles-module--h4--640e8";
export var h5 = "styles-module--h5--52376";
export var p1 = "styles-module--p1--28687";
export var p2 = "styles-module--p2--c786c";
export var p3 = "styles-module--p3--b7dc5";
export var text = "styles-module--text--c3efb";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--77118";
module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TGF9X7R","includeInDevelopment":true,"defaultDataLayer":{"type":"function","value":"() => {\n          const queryStrings = (document && document.location && document.location.search) || {};\n\n          window.utms = queryStrings;\n\n          const queriesObj = queryStrings ? Object.fromEntries(new URLSearchParams(queryStrings)) : {};\n\n          return {\n            platform: `gatsby`,\n            ...queriesObj,\n          };\n        }"},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../plugins/wm-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.elysianpharmaceuticals.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Elysian","short_name":"Elysian+","description":"Elysian, a new blueprint for healthcare.","start_url":"/","background_color":"#FFFFFF","theme_color":"#000000","display":"standalone","icon":"src/images/pwa-icon.png","include_favicon":false,"icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"fdedad0d41ad9a1bff1a274e2112eab9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"navigateFallbackWhitelist":[{}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

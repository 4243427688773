import React, { Fragment } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Section, Text, Form as FormComp, Link, OnVisible } from 'components';
import Twitter from 'images/icons/twitter.inline.svg';
import Instagram from 'images/icons/instagram.inline.svg';
import Linkedin from 'images/icons/linkedin.inline.svg';
import Facebook from 'images/icons/facebook.inline.svg';
import formatPhone from '../../helpers/formatPhone';
import * as styles from './styles.module.scss';

const Form = (props) => {
  const { data } = props;
  const { primary } = data;
  const { tagline, title, form_type: formType } = primary;

  // settings data
  const settingsQ = useStaticQuery(graphql`
    query setQuery {
      ...settingsFragment
    }
  `);

  const {
    prismicSettings: { data: settingsData },
  } = settingsQ;

  const {
    email,
    phone_number: phone,
    twitter,
    facebook,
    instagram,
    linkedin,
    address,
    address_link: addressLink,
  } = settingsData;

  const socials = [
    { comp: <Twitter className={styles.icon} />, link: twitter?.url },
    { comp: <Facebook className={styles.icon} />, link: facebook?.url },
    { comp: <Instagram className={styles.icon} />, link: instagram?.url },
    { comp: <Linkedin className={styles.icon} />, link: linkedin?.url },
  ];

  const isContactForm = formType === 'Contact';

  // contact details
  const contactDetails = [
    {
      name: 'Contact us',
      links: [
        { value: phone?.text, link: `tel:${formatPhone(phone)}` },
        {
          value: isContactForm ? email?.text : 'info@elysianpharmaceuticals.com',
          link: isContactForm ? `mailto:${email?.text}` : 'mailto:info@elysianpharmaceuticals.com',
        },
      ],
    },
    { name: 'Visit us', links: [{ value: address?.text, link: addressLink.url }] },
    { name: 'Follow us', icons: socials },
  ];

  // contact fields
  const contactFields = [
    {
      type: 'text',
      name: 'firstName',
      placeholder: 'Your name (required)',
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please enter your first name',
    },
    {
      type: 'email',
      name: 'email',
      placeholder: 'Your email (required)',
      defaultValue: '',
      validation: { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i },
      validationMessage: 'Please enter a valid email',
    },
    {
      type: 'textarea',
      name: 'message',
      placeholder: 'Your message',
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please enter your message',
    },
  ];

  // doctor sign up fields
  const doctorFields = [
    {
      type: 'text',
      name: 'firstName',
      placeholder: 'First name (required)',
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please enter your first name',
    },
    {
      type: 'text',
      name: 'lastName',
      placeholder: 'Last name (required)',
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please enter your first name',
    },
    {
      type: 'select',
      name: 'title',
      placeholder: 'Title (required)',
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please select a title',
      options: [
        'Title (required)',
        'Dr.',
        'Esq.',
        'Hon.',
        'Jr.',
        'Mr.',
        'Mrs.',
        'Ms.',
        'Msgr.',
        'Prof.',
        'Rev.',
        'Rt. Hon. ',
        'Sr.',
        'St.',
      ],
    },
    {
      type: 'email',
      name: 'email',
      placeholder: 'Your email (required)',
      defaultValue: '',
      validation: { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i },
      validationMessage: 'Please enter a valid email',
    },
    {
      type: 'text',
      name: 'practice',
      placeholder: 'Enter practice name (required)',
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please enter your practice',
    },
  ];

  const fields = isContactForm ? contactFields : doctorFields;
  const formSparkId = isContactForm ? '7SKX46Qo' : 'Ib742dJt';

  return (
    <Section className={styles.section} containerClassName={styles.container} sliceName="Form">
      {tagline?.text && <div dangerouslySetInnerHTML={{ __html: tagline.html }} className={styles.tagline} />}
      <div className={styles.grid}>
        <div className={styles.textColumn}>
          {title?.text && <Text html={title.html} className={styles.title} h2 />}
          <div className={styles.contactSection}>
            {contactDetails.map((item) => {
              const { name, icons, links } = item;
              if (name === 'Visit us' && !isContactForm) return null;
              return (
                <Fragment key={name}>
                  <OnVisible className={styles.contactItem} visibleClassName={styles.visContactItem}>
                    {name && <span className={styles.property}>{name}</span>}
                    {links?.map((linkItem) => {
                      const { link, value } = linkItem;
                      return (
                        <Link to={link || ''} className={styles.value}>
                          {value}
                        </Link>
                      );
                    })}
                    {icons?.length > 0 && (
                      <div className={styles.socials}>
                        {icons.map((icon) => {
                          const { comp, link: compLink } = icon;
                          if (!compLink) return null;
                          return (
                            <Fragment key={compLink}>
                              <Link to={compLink}>{comp}</Link>
                            </Fragment>
                          );
                        })}
                      </div>
                    )}
                  </OnVisible>
                </Fragment>
              );
            })}
          </div>
        </div>
        <div className={styles.formColumn}>
          <span className={styles.formTitle}>{!isContactForm ? 'Fill in your details' : 'Contact us'}</span>
          <FormComp fields={fields} formSparkId={formSparkId} />
        </div>
      </div>
    </Section>
  );
};
export default Form;

const formatPhone = (phone) => {
  const formattedPhone = phone?.text
    .split('')
    .filter((item) => item !== ' ')
    .join('');

  return formattedPhone;
};

export default formatPhone;

import React from 'react';
import { Footer, Header, SEO, NoJs, WmAscii } from 'components';
import '@fontsource/poppins';
import '@fontsource/poppins/600.css';
import '@fontsource/bebas-neue';
import '../../sass/global/styles.scss';
import './styles.scss';

const Layout = (props) => {
  const { children, seo, location, satelliteSite } = props;
  return (
    <>
      <NoJs />
      <WmAscii />
      <Header hideNav={satelliteSite} />
      <main>
        <SEO {...seo} location={location} />
        {children}
      </main>
      <Footer hideNav={satelliteSite} />
    </>
  );
};

export default Layout;

import React from 'react';
import { Logo, Section } from 'components';
import * as styles from './styles.module.scss';

const LoadingPage = ({ text }) => (
  <Section className={styles.section} containerClassName={styles.container}>
    <Logo className={styles.logo} icon />
    <div className={styles.content}>
      <h2>{text || 'Loading, please wait'}</h2>
    </div>
  </Section>
);

export default LoadingPage;

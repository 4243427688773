import React, { Fragment, useEffect } from 'react';
import { graphql } from 'gatsby';
import qs from 'qs';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { Layout, Slice } from 'components';

const Page = ({ location, data, pageContext }) => {
  const { satelliteSite } = pageContext;
  const { prismicPage } = data;
  const { data: pageData } = prismicPage;
  const {
    body: sliceData,
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
    schema,
    noindex,
  } = pageData;
  const seo = {
    title: metaTitle.text,
    desc: metaDesc.text,
    banner: ogImage && ogImage.url,
    schema: schema.text,
    noindex,
  };

  const isHome = location?.pathname === '/';

  useEffect(() => {
    const queries = location?.search ? qs.parse(location.search.replace('?', '')) : {};
    const { error } = queries;
    if (error) {
      alert(error);
    }
  }, []);

  return (
    <Layout location={location} seo={seo} satelliteSite={satelliteSite}>
      {sliceData.map((slice) => (
        <Fragment key={slice.id}>
          <Slice data={slice} isHome={isHome} location={location} />
        </Fragment>
      ))}
    </Layout>
  );
};

export default withPrismicPreview(Page);

export const pageQuery = graphql`
  query PageBySlug($uid: String) {
    prismicPage(uid: { eq: $uid }) {
      _previewable
      id
      uid
      prismicId
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url
        }
        schema {
          text
        }
        noindex
        body {
          ...CenteredCtaFragment
          ...FaqFragment
          ...HeaderPageFragment
          ...IconsStatsTextFragment
          ...TeamCarouselFragment
          # ...TestimonialsFragment
          ...TextBoxBgImageFragment
          ...ThreeColFeaturedFragment
          ...TickerTapeFragment
          ...CardCarouselFragment
          ...ModalTeamGridFragment
          ...ModalGridFragment
          ...FormFragment
          ...PageWysiwyg
          ...ReferralFormFragment
        }
      }
    }
  }
`;

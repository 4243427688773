// extracted by mini-css-extract-plugin
export var arrowSubmit = "styles-module--arrowSubmit--7a8d4";
export var buttonContainer = "styles-module--buttonContainer--8d47f";
export var field = "styles-module--field--96d03";
export var fieldError = "styles-module--fieldError--fbecf";
export var footerForm = "styles-module--footerForm--6e1a4";
export var formButton = "styles-module--formButton--feb99";
export var formSection = "styles-module--formSection--3eb45";
export var grid = "styles-module--grid--2c2ac";
export var label = "styles-module--label--178aa";
export var sectionTitle = "styles-module--sectionTitle--225da";
export var splitLeft = "styles-module--splitLeft--e7e7b";
export var splitRight = "styles-module--splitRight--a345c";
export var successMsg = "styles-module--successMsg--27e1d";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--784db";
import React, { useEffect, useRef } from 'react';
import { Image } from 'components';
import classNames from '../../helpers/classNames';
import './styles.scss';

const isDev = process.env.NODE_ENV === 'development';

const Section = (props) => {
  const { className, containerClassName, children, sliceName, noContainer, bgImage, gradientBg, paralax } = props;

  const containerRef = useRef(null);

  const mouseMoveEvent = (e) => {
    const { x, y } = containerRef.current.getBoundingClientRect();
    containerRef.current.style.setProperty('--x', e.clientX - x);
    containerRef.current.style.setProperty('--y', e.clientY - y);
  };

  useEffect(() => {
    if (containerRef?.current && gradientBg) {
      containerRef.current.addEventListener('mousemove', mouseMoveEvent);
      return () => containerRef?.current?.removeEventListener('mousemove', mouseMoveEvent);
    }
  }, [containerRef, gradientBg]);

  const sectionClasses = classNames('section', {
    [className]: className,
    paralax,
  });

  const containerClasses = classNames('container', {
    [containerClassName]: containerClassName,
    'gradient-bg': gradientBg,
  });

  return (
    <section className={sectionClasses} data-slice={isDev ? sliceName : null}>
      {bgImage && (
        <div className="image-container">
          <Image image={bgImage} className="section-bg" />
        </div>
      )}
      {noContainer ? (
        children
      ) : (
        <div className={containerClasses} ref={containerRef}>
          {children}
        </div>
      )}
    </section>
  );
};

export default Section;

import React, { useState, useEffect } from 'react';
import { Section, Text, Carousel, Link, Image, OnVisible } from 'components';
import LeftArrow from 'images/icons/carousel-button-left.inline.svg';
import RightArrow from 'images/icons/carousel-button-right.inline.svg';
import * as styles from './styles.module.scss';

const TeamCarousel = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { content, tagline, title, cta_link: ctaLink, cta_text: ctaText } = primary;

  const titleHtml = title?.html;
  const taglineHtml = tagline?.html;
  const contentHtml = content?.html;

  const [slick, setSlick] = useState(null);
  const [width, setWidth] = useState(null);
  const [breakPoint, setBreakPoint] = useState(null);
  const slickObj = slick?.current;

  const settings = {
    dots: false,
    infinite: true,
    cssEase: 'linear',
    arrows: false,
    speed: 300,
    slidesToScroll: 1,
    draggable: breakPoint,
    centerMode: false,
    variableWidth: true,
  };
  // custom button handlers
  const handleLeftClick = () => {
    slickObj.slickPrev();
  };
  const handleRightClick = () => {
    slickObj.slickNext();
  };

  // window listener
  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () => {
      window.removeEventListener('resize', () => setWidth(window.innerWidth));
    };
  }, []);
  // set breakpoint state
  useEffect(() => {
    const widthRef = width || window.innerWidth;
    const breakpoint = 1025;
    if (widthRef <= breakpoint) {
      setBreakPoint(true);
    } else {
      setBreakPoint(false);
    }
  }, [width]);

  return (
    <Section containerClassName={styles.container} sliceName="TeamCarousel" noContainer>
      <div className={styles.container}>
        <div className={styles.textContainer}>
          <div>
            {taglineHtml && (
              <Text html={taglineHtml} className={styles.tagline} visibleClassName={styles.taglineVisible} />
            )}
            {titleHtml && <Text html={titleHtml} className={styles.title} visibleClassName={styles.titleVisible} h2 />}
            {contentHtml && (
              <Text html={contentHtml} className={styles.content} visibleClassName={styles.contentVisible} p2 />
            )}
            {ctaText?.text && ctaLink?.url && (
              <Link to={ctaLink.url} className={`cta secondary ${styles.ctaButton}`}>
                {ctaText.text}
              </Link>
            )}
          </div>
          <OnVisible className={styles.buttons} visibleClassName={styles.buttonsVisible}>
            <LeftArrow onClick={handleLeftClick} className={styles.carouselButton} />
            <RightArrow onClick={handleRightClick} className={styles.carouselButton} />
          </OnVisible>
        </div>
        <div className={styles.carouselContainer}>
          <Carousel settings={settings} sliderRef={setSlick}>
            {items.map((item) => {
              const { cta_link: cardCtaLink, cta_text: cardCtaText, image, name, occupation } = item;
              return (
                <div className={styles.card}>
                  <Image image={image} className={styles.image} />
                  {name && <Text html={name.html} h4 className={styles.name} />}
                  {occupation && <Text html={occupation.html} p1 className={styles.occupation} />}
                  {cardCtaLink?.url && cardCtaText?.text && (
                    <Link to={ctaLink.url} className={`cta secondary ${styles.button}`}>
                      {ctaText.text}
                    </Link>
                  )}
                </div>
              );
            })}
          </Carousel>
        </div>
        <div className={styles.mobileButtons}>
          <LeftArrow onClick={handleLeftClick} className={styles.carouselButton} />
          <RightArrow onClick={handleRightClick} className={styles.carouselButton} />
        </div>
      </div>
    </Section>
  );
};
export default TeamCarousel;

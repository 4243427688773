import React from 'react';
import { Section, Text } from 'components';
import MarqueeText from 'react-marquee-text-component';
import * as styles from './styles.module.scss';

const TickerTape = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { tagline } = primary;

  const wordsCollection = [];
  items.forEach((item) => {
    const {
      word: { html: wordHtml },
    } = item;
    const wordContainer = <div dangerouslySetInnerHTML={{ __html: wordHtml }} className={styles.wordContainer} />;
    wordsCollection.push(wordContainer);
  });

  return (
    <Section className={styles.section} containerClassName={styles.container} sliceName="TickerTape" noContainer>
      {tagline?.html && <Text html={tagline.html} className={styles.tagline} p3 />}
      <MarqueeText className="logo-ticker" text={wordsCollection} duration={80} />
    </Section>
  );
};
export default TickerTape;

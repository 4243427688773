import React from 'react';
import './styles.scss';

const FormField = ({
  name,
  type,
  options,
  placeholder,
  defaultValue,
  handleChange,
  register,
  validation,
  otherBox,
}) => {
  if (type === 'textarea') {
    return (
      <textarea
        {...register(name, validation)}
        name={name}
        id={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
      />
    );
  }
  if (type === 'select') {
    return (
      <select name={name} {...register(name, validation)}>
        {options.map((option, index) => (
          <>
            {index === 0 && option === 'Title (required)' ? (
              <option selected disabled hidden value={option} defaultValue={defaultValue} key={option}>
                {option}
              </option>
            ) : (
              <option value={option} defaultValue={defaultValue} key={option}>
                {option}
              </option>
            )}
          </>
        ))}
      </select>
    );
  }

  if (type === 'radio' || type === 'checkbox') {
    return (
      <>
        <div className={`${type}-buttons`}>
          {options.map((option) => (
            <div key={option} className={`${type}-button`}>
              <input
                {...register(name, validation)}
                type={type}
                id={option}
                name={name}
                value={option}
                defaultValue={defaultValue}
                onChange={handleChange}
              />
              <label htmlFor={option}>{option}</label>
            </div>
          ))}
        </div>
        {otherBox && (
          <textarea
            {...register(otherBox?.name, otherBox?.validation)}
            name={otherBox?.name}
            id={otherBox?.name}
            placeholder={otherBox?.placeholder}
            defaultValue={otherBox?.defaultValue}
          />
        )}
      </>
    );
  }
  return (
    <input
      {...register(name, validation)}
      type={type}
      name={name}
      id={name}
      placeholder={placeholder}
      defaultValue={defaultValue}
    />
  );
};

export default FormField;

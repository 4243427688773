import React from 'react';
import { Link } from 'components';
import { siteName } from '../../../config/website';
import * as styles from './styles.module.scss';

const Logo = ({ className }) => {
  const logoClass = process.env.GATSBY_SITE_NAME === 'elysian_holdings' ? styles.elysianLogo : styles.logo;
  return (
    <Link className={`${logoClass} ${className || ''}`} to="/">
      {siteName}
    </Link>
  );
};

export default Logo;

import React from 'react';
import { Section, Text, Link, OnVisible } from 'components';
import * as styles from './styles.module.scss';

const CenteredCta = (props) => {
  const { data } = props;
  const { primary } = data;
  const { cta_link: ctaLink, cta_text: ctaText, title, background_color: bgColor, tagline, content } = primary;

  const widthVariant = title?.text?.length <= 44 ? styles.shortContainer : '';
  const bgColour = bgColor === 'White' ? styles.white : '';
  const buttonColour = bgColor === 'White' ? 'primary' : 'white';
  return (
    <Section
      className={`${styles.section} ${widthVariant} ${bgColour}`}
      containerClassName={styles.container}
      sliceName="CenteredCta"
    >
      <div className={styles.textContainer}>
        {tagline?.text && <div dangerouslySetInnerHTML={{ __html: tagline?.html }} className={styles.tagline} />}
        {title?.text && <div dangerouslySetInnerHTML={{ __html: title?.html }} className={styles.title} />}
        {content?.text && <Text html={content.html} className={styles.content} p2 />}
      </div>
      {ctaLink?.url && ctaText?.text && (
        <OnVisible className={styles.buttonContainer} visibleClassName={styles.visButtonContainer}>
          <Link to={ctaLink.url} className={`button ${buttonColour} ${styles.button}`}>
            {ctaText.text}
          </Link>
        </OnVisible>
      )}
    </Section>
  );
};
export default CenteredCta;

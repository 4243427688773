import React, { Fragment } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Logo, Link, Form } from 'components';
import LinkedIn from 'images/icons/linkedin.inline.svg';
import Facebook from 'images/icons/facebook.inline.svg';
import Instagram from 'images/icons/instagram.inline.svg';
import * as styles from './styles.module.scss';

const Footer = ({ hideNav }) => {
  const data = useStaticQuery(graphql`
    query queryName {
      ...settingsFragment
    }
  `);

  const {
    prismicSettings: { data: settingsData },
  } = data;
  const { facebook, linkedin, instagram, footer_navigation: footerNav } = settingsData;

  const socialLinks = [
    { comp: <LinkedIn className={styles.socialIcon} />, link: linkedin?.url },
    { comp: <Facebook className={styles.socialIcon} />, link: facebook?.url },
    { comp: <Instagram className={styles.socialIcon} />, link: instagram?.url },
  ];

  const columnParents = [...new Set(footerNav.map((item) => item.parent))];

  const fields = [
    {
      type: 'email',
      name: 'email',
      placeholder: 'Enter your email',
      defaultValue: '',
      validation: { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i },
      validationMessage: 'Please enter a valid email',
      className: 'email-field',
    },
  ];

  return (
    <footer className={styles.footer}>
      <div className={`container ${styles.container}`}>
        {/* NAV LINKS */}
        <div className={styles.topSection}>
          <Logo white className={styles.logo} />
          <div className={styles.navigationContainer}>
            {!hideNav &&
              columnParents.map((parent) => (
                <div className={styles.navColumn} key={parent}>
                  <span className={styles.parent}>{parent}</span>
                  {footerNav.map((item) => {
                    const { parent: linkParent, nav_link: link, nav_text: text } = item;
                    if (linkParent !== parent) return null;
                    return (
                      <Fragment key={text.text}>
                        <Link to={link?.url} className={styles.navLink}>
                          {text.text}
                        </Link>
                      </Fragment>
                    );
                  })}
                </div>
              ))}
            <div className={`${styles.navColumn} ${styles.subscribe}`}>
              <span className={styles.parent}>Subscribe</span>
              <Form fields={fields} footer className={styles.footerForm} formSparkId="7SKX46Qo" />
            </div>
          </div>
        </div>
        {/* LEGAL AND SOCIALS */}
        <div className={styles.bottomSection}>
          <p className={styles.legal}>© {new Date().getFullYear()} Elysian. All Rights Reserved</p>
          <div className={styles.socials}>
            {socialLinks.map((item) => {
              const { comp, link } = item;
              if (!link) return null;
              return (
                <Fragment key={link}>
                  <Link to={link}>{comp}</Link>
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

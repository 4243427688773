// extracted by mini-css-extract-plugin
export var contactItem = "styles-module--contactItem--ad1ab";
export var contactSection = "styles-module--contactSection--66eba";
export var formTitle = "styles-module--formTitle--b1b0b";
export var grid = "styles-module--grid--c02c7";
export var icon = "styles-module--icon--a75ae";
export var logo = "styles-module--logo--03b63";
export var property = "styles-module--property--e4891";
export var section = "styles-module--section--0ef6a";
export var socials = "styles-module--socials--29e5a";
export var tagline = "styles-module--tagline--a73c0";
export var title = "styles-module--title--1d14c";
export var value = "styles-module--value--6ee96";
export var visContactItem = "styles-module--visContactItem--c4412";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--baa3d";
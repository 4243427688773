// extracted by mini-css-extract-plugin
export var bottomSection = "styles-module--bottomSection--46cfa";
export var container = "styles-module--container--8ea9e";
export var footer = "styles-module--footer--a2167";
export var legal = "styles-module--legal--b5fb9";
export var logo = "styles-module--logo--be6d4";
export var navColumn = "styles-module--navColumn--a2462";
export var navLink = "styles-module--navLink--fb757";
export var navigationContainer = "styles-module--navigationContainer--adff7";
export var parent = "styles-module--parent--8e2ea";
export var socialIcon = "styles-module--socialIcon--4e408";
export var socials = "styles-module--socials--743b0";
export var subscribe = "styles-module--subscribe--56882";
export var topSection = "styles-module--topSection--17ab2";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--e1384";
// extracted by mini-css-extract-plugin
export var buttons = "styles-module--buttons--62064";
export var buttonsVisible = "styles-module--buttonsVisible--a5351";
export var carouselButton = "styles-module--carouselButton--645df";
export var carouselContainer = "styles-module--carouselContainer--4d4d4";
export var container = "styles-module--container--fb69b";
export var content = "styles-module--content--2623b";
export var contentVisible = "styles-module--contentVisible--f39da";
export var ctaButton = "styles-module--ctaButton--aaaff";
export var image = "styles-module--image--1de1a";
export var mobileButtons = "styles-module--mobileButtons--57ebb";
export var name = "styles-module--name--f43bc";
export var occupation = "styles-module--occupation--fe92c";
export var tagline = "styles-module--tagline--5f5c1";
export var taglineVisible = "styles-module--taglineVisible--f9a23";
export var textContainer = "styles-module--textContainer--b4411";
export var title = "styles-module--title--92264";
export var titleVisible = "styles-module--titleVisible--be248";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--8f76b";
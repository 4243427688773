import React from 'react';
import { Section, Text, Link, OnVisible, Image } from 'components';
import * as styles from './styles.module.scss';

const Header = (props) => {
  const { data, isHome } = props;
  const { primary } = data;
  const { content, cta_text: ctaText, cta_link: ctaLink, video, title, image, hero_image: heroImage } = primary;

  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const hasCta = ctaLink?.url && ctaText?.text;
  const homeVariant = isHome ? styles.homeVariant : '';

  return (
    <Section
      containerClassName={styles.container}
      className={`${styles.section} ${homeVariant}`}
      bgImage={heroImage}
      sliceName="Header"
    >
      <div className={styles.textContainer}>
        {titleHtml && <Text html={titleHtml} className={styles.title} visibleClassName={styles.titleVisible} />}
        {contentHtml && (
          <Text
            html={contentHtml}
            p3={isHome}
            p2={!isHome}
            className={styles.content}
            visibleClassName={styles.contentVisible}
          />
        )}
        {hasCta && (
          <OnVisible className={styles.buttonContainer} visibleClassName={styles.buttonContainerVisible}>
            <Link to={ctaLink.url} className={`button white ${styles.button}`}>
              {ctaText.text}
            </Link>
          </OnVisible>
        )}
      </div>
      {/* normal variant */}
      {image?.url && (
        <OnVisible className={styles.logoContainer} visibleClassName={styles.visibleLogoContainer}>
          {image?.url && <Image image={image} className={styles.headerImage} />}
        </OnVisible>
      )}
    </Section>
  );
};

export default Header;

import React, { useState, useEffect } from 'react';
import { Section, Link } from 'components';
import { animate, spring } from 'motion';
import * as styles from './styles.module.scss';

const Hamburger = ({ active, onClick, className }) => {
  // STATE MANAGEMENT
  const [hovering, setHovering] = useState(false);

  // ANIMATIONS
  useEffect(() => {
    // Top line
    animate(
      '#top',
      {
        transform: active ? 'rotate(-45deg)' : 'rotate(0deg)',
        width: '27px',
        top: active ? '16px' : '11px',
      },
      { duration: 0.2, easing: 'ease-in' }
    );
    // Bottom line
    animate(
      '#bottom',
      {
        transform: active ? 'rotate(45deg)' : 'rotate(0deg)',
        width: hovering || active ? '27px' : '15px',
        top: active ? '16px' : '19px',
      },
      { duration: 0.2, easing: 'ease-in' }
    );
    animate(
      '#container',
      {
        transform: active ? 'rotate(180deg)' : 'none',
      },
      { duration: 0.3, easing: 'ease-in' }
    );
  }, [active, hovering]);

  return (
    <div
      id="container"
      className={`${styles.expand} ${styles.container} ${className || ''}`}
      onMouseOver={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      onClick={onClick}
      role="button"
      tabIndex="0"
    >
      <span id="top" className={styles.top} />
      <span id="bottom" className={styles.bottom} />
    </div>
  );
};

export default Hamburger;
